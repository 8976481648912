if ($('#submit-flower-photo').length) {
    Vue.config.delimiters = [ '{@', '@}' ];

    new Vue({
        el: '#submit-flower-photo',
        data: {
            submitting: true
        },

        methods: {

        }
    });
}