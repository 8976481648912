if ($('.images-admin-app').length) {
    Vue.config.delimiters = [ '{@', '@}' ];

    new Vue({
      el: '.images-admin-app',
      data: {
          images: []
      },
      ready: function() {
          if ($('#images-hidden').html().length) {
            var images = JSON.parse($('#images-hidden').html());

            if (images.length) {
              this.images = images;
            }
          }

            this.addImage();          
      },
      methods: {
        addImage: function() {
            this.images.push({
                name: '',
                path: '',
                caption: '',
                attribution: ''
            });
        },
        removeImage: function(index) {
            this.images = _.without(this.images, this.images[index]);
        }
      }
    });
}