if ($('#grower_form').length) {
    Vue.config.delimiters = [ '{@', '@}' ];

    var app = new Vue({
      el: '#grower_form',
      data: {
          images: [],
          existing_images: []
      },
      ready: function() {
          var count = 0;
          if ($('#images-hidden').length) {
                var images = JSON.parse($('#images-hidden').html());

                if (images.length) {
                    this.images = images;
                    this.existing_images = images;
                }

                count = 5 - this.images.length;
          }

          if (count) {
              for(var i = 0; i <= count; i++) {
                  this.images.push({
                      path: ''
                  });
              }
          }
      },
      methods: {
        removeImage: function(index) {
            this.images[index].path = '';
        }
      }
    });
}