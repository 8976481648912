var AtdMessenger = (function(){
	this.active = false;
	this._parent = {};
	this._slug = '';    
    
	var $this = this;
    
	this._receiveMessage = function(event) {
		try {
			// only accept JSON STRING.
			var _data = event.data;
		} catch (e) {
			// fail silently
			return;
		}

		if (typeof _data.call != 'undefined')
		{   
			var action = $this[_data.call];
			return action(event);
		}
        
	};
    
	this.setParent = function(event) {
		$this._parent = {
			"source" : event.source, 
			"origin": event.origin
		};
		$this.active = true;
		return $this;
	}
    
	this.addThis = function(type) {
		return $this.sendMessage('{"add":"'+type+'"}');
	} 
        
	this.setSlug = function(slug) {
		return $this.sendMessage('{"call":"setSlug", "slug":"'+slug+'"}');
	}
    
	this.sendMessage = function(message) {
		if (this.active)
			return $this._parent.source.postMessage(message, $this._parent.origin);
	}

	return {
		"receiveMessage" : $this._receiveMessage,
		"setSlug" : $this.setSlug
	};
})();

try {
	window.addEventListener("message", AtdMessenger.receiveMessage, false);
} catch (e) {
	window.attachEvent("message", AtdMessenger.receiveMessage);
}

var meta = (function(){
	this._p = {
		url: document.location.href,
		image:'',
		title:'',
		hash:''
	};
		
	var $this = this;
	
	this._get = function(_att) {
		var _r = null;
		for (var _prop in $this._p) {
			if (_att == _prop) {
				_r = $this._p[_prop];
			}
		}
		return _r;
	}
	
	this._set = function(_att, _val) {
		
		if ('url' == _att) {
			if (0 === _val.indexOf('#')) {
				return $this._set('url',$this._stripHash($this._get('url')) + (0 == _val.indexOf('#') ? '' : '#' ) +_val);
			}
		}
		
		if ('hash' == _att ) {
			if (_val.length > 0)
				AtdMessenger.setSlug(_val);
			$this._p[_att] = _val;
			return $this._set('url',$this._stripHash($this._get('url')) + (0 == _val.indexOf('#') ? '' : '#' ) +_val);
		}
		
		for (var _prop in $this._p) {
			if (_att == _prop) {
				$this._p[_prop] = _val;
				$('div.addthis_toolbox').each(function(){
					$(this).attr('addthis:'+_prop,_val);
				});
			}
		}
	}
	
	this._stripHash = function(_url) {
		return (_url.indexOf('#') > -1 ? _url.substr(0, _url.indexOf('#')) : _url);
	}
	
	this._reloadToolbox = function() {
		var tbx = document.getElementById("toolbox"),
		svcs = {
			email: 'Email', 
			print: 'Print', 
			facebook: 'Facebook', 
			expanded: 'More'
		};
		
		tbx.innerHTML = '';
		for (var s in svcs) {
			tbx.innerHTML += '<a class="addthis_button_'+s+'"></a>';
		}
		
		addthis.toolbox("#toolbox");
	}
	
	if (window.name.length > 0 && 0 === window.name.indexOf('http')) {
		this._set('url', $this._stripHash(window.name));
		if (window.name.indexOf('#') > -1) {
			this._set('hash',window.name.substr(window.name.indexOf('#')));
		}
	} else {
		this._set('url', $this._stripHash(window.location.href));
		this._set('hash',window.location.hash);
	}
	
	return {
		'set': this._set,
		'get': this._get,
		'reload' : this._reloadToolbox
	};
	
})();

var RecaptchaOptions = {
	theme : 'clean'
};

function showRecaptcha(element,error) {
	Recaptcha.create("{{recaptcha_public_key()}}", element, RecaptchaOptions);
}

$(document).ready(function() {
	initMasks();

	$('[data-equal-height]').each(function() {
		setEqualHeight($(this), $(this).data('equal-height'));
	});

	if($('.flower-search-form select').length && typeof $.fn.customSelect === 'function'){
		$('.flower-search-form select').not('[multiple="multiple"]').customSelect();
	}

    if (typeof placeholder != 'undefined') {
	    $('input, textarea').placeholder();
    }

	$('body').on('contextmenu', 'img,.flower-image', function(){ 
		return false;
	});

	var isMSIE = /*@cc_on!@*/0;
	var _fb = $('.fall_back');
	if (_fb.length > 0) {
		_fb.hide();
		$('.primary').removeClass('hidden');
	}
	
	// Replace selects with custom styling
	if (!isMSIE) {
		var selects = $('select[rel="atd-select"]');
		if(selects.length > 0) {
			$('select[rel="atd-select"]').atdSelect();
		}
	}
	// End
	
	//toggle sign in popup
	$('.top-nav .login-toggle').click(function(e) {
		e.preventDefault();
		$('.top-nav .user-login').fadeToggle(300, function(){
			$('.top-nav .user-login:visible #username').focus();
		});
	});
	
	//bootstrap alerts
	if ($(".alert.auto").length > 0)
	{
		setTimeout(function(){
			$(".alert.auto").alert('close');
		}, 6000);
	}
	
	var _dt = $('span.add-on.icon-calendar');
	if (_dt.length > 0) {
		for (var _idx = 0; _idx < _dt.length; _idx++) {
			new function() {
				var _target = _dt.eq(_idx).parent().find('input');
				if (_target.length > 0) {
					_target.datepicker()
					.on('changeDate', function(e){
						_target.datepicker('hide');
					});

					_dt.eq(_idx).click(function(e){
						e.preventDefault();
						_target.eq(0).datepicker("show");
					});

					_target.on('change', function(e) {
						if (!$(this).val())
						{
							var date = new Date();
							var month = ('0' + (date.getMonth()+1)).slice(-2);
							var day = date.getUTCDate();
							var year = date.getUTCFullYear();

							$(this).val(month + '/' + day + '/' + year);
							_target.datepicker('update');
						}
					});
				}
			}();
		}
	}

	$(".btn-confirm").on('click',function(e) {
		e.preventDefault();

		if ($(this).attr('title'))
		{
			var text = $(this).attr('title');
		}
		else
		{
			var text = 'Remove Record?';
		}

		if (confirm(text))
		{
			if ($(this).attr('href') && !$(e.target).attr('form'))
			{
				document.location = $(this).attr('href');
			} else { 
				if (!$(e.target).attr('form') )
				{
					return false;
				}
			}

			var form = $('#'+$(e.target).attr('form'));

			form.submit();

			return true;
		}

		return false;
	});

	/** Embeded agreement showing/hiding ****/
	$('#embed-agreement .agreement').change(function(){
		console.log($('#embed-agreement .agreement1').is(':checked'));
		if($('#embed-agreement .agreement1').is(':checked') && $('#embed-agreement .agreement2').is(':checked')) {
			$('.embed-code-block').fadeIn(200);
		}
		else {
			$('.embed-code-block').fadeOut(200);
		}
	});
	
	/**** Copy embed code to clipboard ******/
	if(typeof ZeroClipboard === 'function') {
		ZeroClipboard.setDefaults( {
			moviePath: '/js/zeroclipboard/ZeroClipboard.swf'
		} );
		var clip = new ZeroClipboard($('.embed-copy-btn'));
	}

	if(typeof quoteSource !== 'undefined') {
		var quoteRotator = new QuoteRotator();
		quoteRotator.initQuotes(quoteSource, $('.testimonial span'), 8000);
	}

	var retailerNav = $('ul.horizontal .acc-trigger');

	if (retailerNav.length) {
		var accordionSliding = false;
		retailerNav.click(function(e) {
			e.preventDefault();

			if (!accordionSliding) {
				accordionSliding = true;
				if ($(this).hasClass('active')) {
					$(this).removeClass('active').siblings('.acc-child').removeClass('active');
					$(this).siblings('.acc-child').slideToggle(300, function() {
						accordionSliding = false;
					});
				} else {
					$('ul.horizontal .acc-child, ul.horizontal .acc-trigger').removeClass('active');
					$(this).toggleClass('active').siblings('.acc-child').toggleClass('active');
					$('ul.horizontal .acc-child').not('.active').slideUp(300);
					$(this).siblings('.acc-child').slideToggle(300, function() {
						accordionSliding = false;
					});
				}
			}
		});
	}
});

/**** Object Definitio For Quote Rotator *******/
function QuoteRotator() {
	
	this.source = {}; // json object of quotes
	this.element = {}; // jquery element that holds quote
	
	// retrevie random quote
	this.getQuote = function(source) {
		var result;
		var count = 0;
		for (var prop in source)
			if (Math.random() < 1/++count)
				result = prop;
		return source[result];
	};
	
	// fade out, switch quote, fade in
	this.switchQuote = function() {
		var self = this;
		self.element.fadeOut(300, function(){
			$(this).html(self.getQuote(self.source)).fadeIn(300);
			
		});
	}
	
	// init quotes
	this.initQuotes = function(source, element, delay) {
		var self = this;
		
		if(element.length) {
			self.element = element;
			self.source = source
			setInterval(function(){
				self.switchQuote();
			}, delay);
		}
	}

	return this;
}

function setEqualHeight($container, element) {
	var height = 0;
	$container.find(element).each(function() {
		if($(this).height() > height) {
			height = $(this).height();
		}
	});
	$container.find(element).height(height);
}

function initMasks()
{
	var _sites = $('input.siteMask');
	if (_sites.length > 0) {
		for (var _idx = 0; _idx < _sites.length; _idx++) {
			_sites.eq(_idx).attr('placeholder',"http://");
			_sites.eq(_idx).change(function(e) {
				var _prot = /^http.*/i;
				if (_prot.test(e.target.value) == false && e.target.value.length > 0)
					e.target.value = 'http://' + e.target.value;
			
				return;
			});
		}
	}
}
