var dashboard = angular.module('DashboardApp', []);

dashboard.controller('DashboardCtrl', function DashboardCtrl($scope, $http) {
	$scope.loading = false;
	$scope.statuses = {
		'website': false,
		'customer_accounts': false,
		'flower_names': 'official'
	};

	$scope.init = function() {
		$scope.statuses = {
			'website': $('#website').length && $('#website').html() == 1 ? true : false,
			'customer_accounts': $('#customer_accounts').length && $('#customer_accounts').html() == 1 ? true : false,
			'flower_names': $('#flower_names').length ? $('#flower_names').html() : 'official'
		};
	};

	$scope.toggleStatus = function(key, status) {
		if (!$scope.loading) {
			$.blockUI();

			$scope.statuses[key] = status;
			$scope.loading = true;

			var url = '/retailer/dashboard/toggle-statuses';

			url += '?active=' + $scope.statuses.website;
			url += '&customer_accounts=' + $scope.statuses.customer_accounts;
			url += '&flower_names=' + $scope.statuses.flower_names;

			$http.get(url)
				.success(function() {
					$scope.loading = false;

					if (key == 'customer_accounts' && $('.customer-albums').length) {
						// $('.customer-albums').slideToggle(500);
					}

					$.unblockUI();
				});
		}
	};
});
